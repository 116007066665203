/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql } from "gatsby";
import { AppContext } from "~context/AppContext";
import AboutLayout from "~components/AboutLayout";
import Layout from "~components/Layout";
import PastEvents from "~components/PastEvents";
import SEO from "~components/SEO";

class AboutPageComponent extends Component {
  componentDidMount() {}

  //

  render() {
    const { frontmatter, location } = this.props;

    return (
      <>
        <SEO
          customTitle={frontmatter.title}
          customDescription={frontmatter.seoDescription}
          customKeywords={frontmatter.seoKeywords}
          path={location.pathname}
        />

        <Layout className="about-page relative">
          <AboutLayout frontmatter={frontmatter} />

          <PastEvents />
        </Layout>
      </>
    );
  }
}

const AboutPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <AppContext.Consumer>
      {appContext => (
        <AboutPageComponent
          appContext={appContext}
          frontmatter={frontmatter}
          location={location}
        />
      )}
    </AppContext.Consumer>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        intro
        introAuthor
        artist1Author
        artist1Quote
        artist2Author
        artist2Quote
        artist1 {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        artist2 {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        feature1 {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        feature2 {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        feature3 {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        feature4 {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        aboutVideo
        seoDescription
        seoKeywords
      }
    }
  }
`;
